<template>
  <v-layout class="input-flex">
    <v-flex class="input-flex" v-if="question.choiceItems !== undefined" wrap>
      <v-flex class="input-flex d-block p-t-10" v-for="(choice, n) in question.choiceItems" :key="n">
        <div class="d-block" v-if="isIe && choice.hasSubQuestion && question.answers.includes(choice.choiceValue)">
          <label>
            {{choice.content}} 답 입력
            <input
              class="width-p-100"
              v-model="inputTxt[choice.choiceValue]"
              style="border-bottom: 2px solid #444;"
              :id="question.questionId + '-' + choice.choiceValue"
              @input="subAnswer[choice.choiceValue] = inputTxt[choice.choiceValue]; insertTxt(question, true);"
            />
          </label>
        </div>
        <v-text-field
          v-if="choice.hasSubQuestion && question.answers.includes(choice.choiceValue)"
          class="text-field"
          :label="choice.content + ' 답 입력'"
          :class="{'none': isIe }"
          @input="insertTxt(question, true)"
          :rules="textRules"
          v-model="subAnswer[choice.choiceValue]"
        ></v-text-field>
      </v-flex>
    </v-flex>
    <v-flex class="input-flex" v-else-if="question.questionType === 3" wrap>
      <input
        v-if="isIe"
        class="width-p-100"
        v-model="inputTxt"
        style="border-bottom: 2px solid #444;"
        @input="question.answers = inputTxt; insertTxt(question, true);"
      />
      <v-text-field
        outline
        class="text-field"
        :class="{'none': isIe}"
        @input="insertTxt(question, true)"
        :rules="textRules"
        v-model="question.answers"
      ></v-text-field>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'AnswerText',
  props: {
    question: Object,
    subAnswer: Object,
    insertTxt: Function,
  },
  computed: {
    isIe () {
      let agent = navigator.userAgent.toLowerCase()
      if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (agent.indexOf("msie") != -1) ) { // IE 일 경우
        return true
      } else {
        return false
      }
    }
  },
  data () {
    return {
      textRules: [
        v => !(/[¸|ː|§]/.test(v)) || '해당 특수문자는 사용할 수 없습니다.',
        v => typeof v === 'string' && v.trim().length > 0 || '공백만 입력하는 것은 불가합니다.'
      ],
      inputTxt: []
    }
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scope>
.v-text-field>.v-input__control>.v-input__slot>.v-text-field__slot input{
  margin-top: 10px;
}
.none {
  visibility: hidden;
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>
