<template>
  <!-- <v-layout class="p-t-8 p-b-16"> -->
  <v-layout class="">
    <v-flex class="d-block" v-html="question.questionTitle"></v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'AnswerLine',
  props: {
    question: Object
  }
}
</script>
