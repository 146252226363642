<template>
  <v-container class="layout-container" grid-list-xl>
    <v-flex xs12 sm8 offset-sm2 class="flex-container m-y-30">
      <v-layout wrap v-if="survey !== undefined && isAdmin" color="grey" class="headline font-weight-bold p-a-16">
        <v-flex xs12 class="text-xs-left"><img src="@/assets/images/logo.png" alt="u2survey logo"></v-flex>
        <v-flex xs12 class="text-xs-left" style="margin-top: 21px;">{{survey.contents}} 미리보기</v-flex>
      </v-layout>
      <v-layout wrap v-else color="grey" class="headline font-weight-bold p-a-16">
        <v-flex xs12 class="text-xs-left"><img src="@/assets/images/logo.png" alt="u2survey logo"></v-flex>
      </v-layout>
      <v-card class="mx-auto">
        <steppers
          v-if="questionList.length > 0 && getSectionsList.length > 0 && sectionArr.length > 0"
          :questions="questionList"
          :isSubmitResult="isSubmitResult"
          :section-arr="sectionArr"
          :result-view="resultView"
          :visibleSection="visibleSection"
          :visibleQuestionPercentage="visibleQuestionPercentage"
          :get-answer-str="getAnswerStr"
          :submitDropoutsAnswer="submitDropoutsAnswer"
          :survey="survey"
          :send="send"
          :is-admin="isAdmin"
        >
        </steppers>
      </v-card>
    </v-flex>
    <div v-if="!send || !isFetchedData" style="position: fixed; width: 100vw; height: 100vh; z-index: 20000; left: 0; top: 0; background-color: rgba(0,0,0,0.5)">
      <v-progress-circular
        style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); z-index: 20001;"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </v-container>
</template>

<script>

import Steppers from '@/components/users/Steppers.vue'
import { surveyMapGetters, surveyMapActions } from '../store/modules/survey/survey'
import _ from 'lodash'

export default {
  name: 'SurveyUser',
  data () {
    return {
      isFetchedData: false,
      surveyId: 0,
      res: '',
      answerStr: '',
      choicedAnswers: new Set(),
      sectionArr: [],
      send: true,
      survey: {},
      resultObjList: [],
      rdurl: '',
      questionList: [],
      isSubmitResult: false
    }
  },
  computed: {
    ...surveyMapGetters(['getSectionsList', 'getQuestionsList', 'getAnswerList', 'getAnswerByAnswerId']),
    isAdmin () {
      return this.res === 'admin'
    },
    visibleQuestionPercentage () { 
      let totalLength = _.sortedUniq(this.questionList.filter(el => el.isVisible && el.isUse && el.questionType !== 5 && el.questionType !== 8 && el.questionType !== 9)).length
      return Math.floor(((totalLength - _.sortedUniq(this.questionList.filter(el => el.isVisible && el.isUse && el.questionType !== 5 && el.questionType !== 8 && el.questionType !== 9 && (!el.answers || el.answers.length === 0 || el.answers === ''))).length) / totalLength) * 100)
    },
    visibleQuestion () { 
      return _.sortedUniq(this.questionList.filter(el => el.isVisible && el.isUse).map(el => { return el.sectionId }))
    },
    visibleSection () {
      return this.sectionArr.filter(el => this.visibleQuestion.includes(el.sectionId) && el.isUse)
    }
  },
  methods: {
    ...surveyMapActions(['fetchSurveyData', 'fetchAnswerListByRespondent', 'fetchAnswerByAnswerId', 'createAnswerInfo', 'submitResult', 'submitResultForDropouts', 'fetchSurveyInfo']),
    resultView (questionId, sectionId) {
      // 답변자가 답변 선택할 때 마다 도는 메소드
      this.choicedAnswers.clear()
      this.calcVisibleGetAnswer([questionId], true, true)
      this.calVisibleQuestion(this.choicedAnswers)
      this.calVisibleSection()
      if (sectionId !== this.sectionArr[this.sectionArr.length - 1].sectionId) {
        return ''
      } else {
        this.answerStr = ''
        let lastPageQuestion = this.questionList.filter(question => { return question.sectionId === sectionId })
        if (lastPageQuestion.length >= 1) return ''
        this.getAnswerStr(sectionId)
      }
    },
    submitDropoutsAnswer (index) {
      if (this.isAdmin || this.survey.isEditable) return ''
      this.resultObjList = []
      for (const q of this.questionList) {
        if (q.questionType === 8 || q.questionType === 5 || q.questionType === 9 || !q.isVisible || !q.isUse) continue
        if (q.answers === undefined || q.answers.length === 0) {
          this.resultObjList.push({ answerIdx: q.answerIdx, choiceValue: null, textValue: null })
        } else {
          if (q.answers !== undefined && q.answers.length > 0 && (q.questionType === 7 || q.questionType === 3 ||  q.questionType === 31)) {
            this.resultObjList.push({ answerIdx: q.answerIdx, choiceValue: null, textValue: q.answers })
          } else {
            for (const answer of q.answers) {
              if (q.subAnswer !== undefined &&  q.subAnswer[answer] !== undefined) {
                this.resultObjList.push({ answerIdx: q.answerIdx, choiceValue: answer, textValue: q.subAnswer[answer] })
              } else {
                this.resultObjList.push({ answerIdx: q.answerIdx, choiceValue: answer, textValue: null })
              }
            }
          }
        }
      }
      const answerLength = this.resultObjList.filter(el => el.choiceValue != null || el.textValue != null).length
      if (this.resultObjList.length === 0 || answerLength === 0) {
        return ''
      }
      this.submitResultForDropouts({
        params: {
          info: {
            surveyId: this.surveyId,
            respondent: this.res
          },
          result: this.resultObjList
        }
      })
    },
    getAnswerStr (index) {
      if (this.isAdmin) return ''
      // 마지막 질문까지 답변을 했을 경우 답변 제출을 위해 답변을 obj로 묶어서 답변insert api 찌름
      if (this.send) {
        this.send = false
        this.resultObjList = []
        for (const q of this.questionList) {
          if (q.questionType === 8 || q.questionType === 5 || q.questionType === 9 || !q.isVisible || !q.isUse) continue
          if ((q.questionType === 7 || q.questionType === 3 ||  q.questionType === 31)) {
            if (q.answers === '' || q.answers.length === 0) continue
            if (q.answers === '') continue
            this.resultObjList.push({ answerIdx: q.answerIdx, textValue: q.answers })
          } else {
            if (q.answers === undefined || q.answers.length === 0) continue
            for (const answer of q.answers) {
              if (q.subAnswer !== undefined &&  q.subAnswer[answer] !== undefined) {
                this.resultObjList.push({ answerIdx: q.answerIdx, choiceValue: answer, textValue: q.subAnswer[answer] })
              } else {
                this.resultObjList.push({ answerIdx: q.answerIdx, choiceValue: answer, textValue: '' })
              }
            }
          }
        }
        if (this.resultObjList.length === 0) {
          alert ('제출할 내용이 없습니다.')
          this.send = true
          return ''
        }
        this.isSubmitResult = true
        this.submitResult({
          params: {
            info: {
              surveyId: this.surveyId,
              respondent: this.res
            },
            result: this.resultObjList
          }
        }).then((response) => {
          alert(response.data.retMsg)
          if (response.data.isSuccess === 'Y') {
            if (this.rdurl.length > 0) {
              window.location.replace(this.rdurl)
            } else {
              if (this.$route.name !== 'SurveyUserFinish') this.$router.push({name: 'SurveyUserFinish'})
            }
          }
        }).catch((err) => {
          this.send = true
        })
      }
    },
    calcVisibleGetAnswer (noArr, isRoot) {
      // 현재 선택한 답변의 하위 질문에 해당하는 질문들만 isVisible 로 만들어 주기 위한 재귀함수
      if (!noArr || noArr.length === 0) return ''
      let selectedQuestionList = this.questionList.filter(question => noArr.includes(question.questionId))
      if (!selectedQuestionList) return ''
      this.questionList = this.questionList.map(question => {
        if (noArr.includes(question.questionId)) { question.isVisible = isRoot }
        return question
      })
      // 하위 선택지의 questionId 배열
      let choiceLinkArr = _.uniq(
        _.flattenDeep(
          selectedQuestionList.map(el => {
            return _.flattenDeep(_.map(el.choiceItems, 'linkedQ'))
          })
        )
      )
      // 선택한 답변에서 isVisible true가 되어야 하는 questionId 배열
      let answerQuestions = _.flattenDeep(selectedQuestionList.map(question => {
        if (question.choiceItems) {
          return question.choiceItems.filter(el => { return question.isVisible && question.answers.includes(el.choiceValue) }).map(el => el.linkedQ)
        } else {
          return {}
        }
      }))
      let choiceLinkArrLeng = choiceLinkArr.length
      if (!choiceLinkArrLeng || choiceLinkArrLeng === 0) return ''
      if (answerQuestions && answerQuestions.length > 0) {
        this.choicedAnswers = new Set([...this.choicedAnswers, ...new Set(answerQuestions)])
      }
      this.calcVisibleGetAnswer(choiceLinkArr, false)
    },
    calVisibleQuestion (choicedAnswers) {
      // 선택한 질문 isVisible 로 만드는 메소드
      if (choicedAnswers && choicedAnswers.size > 0) {
        this.questionList = this.questionList.map(el => {
          if (choicedAnswers.has(el.questionId)) { el.isVisible = true }
          return el
        })
      }
      // 23-01-26
      // choicedAnswers.forEach(choicedAnswer => {
      //   this.getQuestionsList.filter(question => {
      //     if (question.questionId === choicedAnswer) {
      //       question.isVisible = true
      //     }
      //   })
      // })
    },
    calVisibleSection () {
      // 답변자에게 보여져야 하는 질문을 포함하고 있는 section만 isVisible로 처리하는 메소드
      this.sectionArr = []
      let questionList = this.questionList.map(el => { return el.sectionId })
      if (!questionList || questionList.length === 0) return ''
      let sectionIdList = this.sectionArr.map(el => { return el.sectionId })
      if (!sectionIdList || sectionIdList.length > 0) sectionIdList = []
      let sectionList = this.getSectionsList.filter(el => (questionList.includes(el.sectionId)  && (el.isUse || this.isAdmin)) && !sectionIdList.includes(el.sectionId))
      if (sectionList && sectionList.length > 0) {
        sectionList.forEach(section => {
          section.isVisible = true
          this.sectionArr.push(section)
        })
      }
    },
    // 23-01-26
    // calVisibleSection () {
    //   // 답변자에게 보여져야 하는 질문을 포함하고 있는 section만 isVisible로 처리하는 메소드
    //   let sectionIdList = this.sectionArr.map(el => { return el.sectionId })
    //   console.log(sectionIdList)
    //   this.sectionArr = []
    //   this.getQuestionsList.forEach(question => {
    //     if (question.isVisible) {
    //       this.getSectionsList.forEach(section => {
    //         if (section.sectionId === question.sectionId && (section.isUse || this.isAdmin)) {
    //           if (!_.find(this.sectionArr, {sectionId: section.sectionId})) {
    //             section.isVisible = true
    //             this.sectionArr.push(section)
    //           }
    //         }
    //       })
    //     }
    //   })
    // },
    getInitValues (question, answer) {
      // 답변 str이 있다면 각 질문의 답변으로 처리해주는 메소드이나 현재 사용하지 않음
      if (answer !== '') {
        if (question.questionType === 1 || question.questionType === 4 || question.questionType > 40) {
          question.answers.push(parseInt(answer))
        } else if (question.questionType === 7 || question.questionType === 3 || question.questionType === 31) {
          question.answers.push(answer)
        } else if (question.questionType === 2) {
          if (answer.includes('ː')) {
            let ck = answer.split('¸')
            ck.forEach(c => {
              if (c.includes('ː')) {
                let bb = c.split('ː')
                question.answers.push(parseInt(bb[0]))
                question.subAnswer = {[parseInt(bb[0])]: bb[1]}
              } else {
                question.answers.push(parseInt(c))
              }
            })
          } else if (answer.includes('¸')) {
            answer.split('¸').forEach(el => {
              question.answers.push(parseInt(el))
            })
          } else {
            question.answers.push(parseInt(answer))
          }
        }
      }
    },
    setSurvey (linkQuestion) {
      // 설문 초기화하는 메소드
      this.fetchSurveyData({
        surveyId: this.surveyId
      }).then(() => {
        // if (this.answerStr !== '') var answersArray = this.answerStr.split('§')
        this.questionList = _.cloneDeep(this.getQuestionsList)
        this.questionList.forEach((question, index) => {
          question.questionTitle = question.questionTitle
          question.answers = []
          if (question.choiceItems !== undefined && question.choiceItems.length > 0) {
            question.choiceItems.forEach(choice => {
              if (choice.linkedQ !== undefined && choice.linkedQ.length > 0) {
                choice.linkedQ.forEach(linkId => {
                  linkQuestion = this.questionList.filter(question => { return question.questionId === linkId })[0]
                  if (linkQuestion !== undefined) linkQuestion.isVisible = false
                })
              }
            })
          }
          if (question.isVisible === undefined) question.isVisible = true
          // if (this.answerStr !== '' && answersArray.length > 0) this.getInitValues(question, answersArray[index])
        })
        this.calcVisibleGetAnswer([this.questionList[0].questionId], true, true)
        this.calVisibleQuestion(this.choicedAnswers)
        this.calVisibleSection()
      }).catch((err) => {
        throw Error(err)
      }).finally(() => {
        this.isFetchedData = true
      })
    },
    formatDate(d) {
      let month = '' + (d.getMonth() + 1)
      let day = '' + d.getDate()
      let year = d.getFullYear()
      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day
      return new Date(year + '-' + month + '-' + day);
    }
  },
  components: {
    'steppers': Steppers
  },
  created () {
    window.addEventListener("beforeunload", this.closeBrowser)
    let linkQuestion
    let fullUrl = window.location.pathname.split('/param/')[1]
    if (fullUrl === undefined && fullUrl.length === 0) {
      alert('유효한 설문주소가 아닙니다.')
      return ''
    }
    let surveyIdParamStr = atob(this.$route.params.param.replace(/_/gi, '/'))
    this.surveyId = surveyIdParamStr.split('/')[1]
    this.res = this.$route.params.res ? this.$route.params.res : ''
    if (window.location.pathname.indexOf('/rdurl/') > 0) {
      let splitRdurl = window.location.pathname.split('/rdurl/')
      if (splitRdurl.length > 0) {
        let replacedSplitRdurl = splitRdurl[1].replace(/\r\n/g, '')
        this.rdurl = atob(replacedSplitRdurl).replace(/_/gi, '/')
      }
    }
    this.isFetchedData = false
    this.fetchSurveyInfo({
      surveyId: this.surveyId
    }).then((response) => {
      this.survey = _.cloneDeep(response.data)
      let currentDate = this.formatDate(new Date())
      let startDate = this.formatDate(new Date(this.survey.startDate))
      let endDate = this.formatDate(new Date(this.survey.endDate))
      let resCnt = 0
      if (this.survey === undefined) {
        this.isFetchedData = true
        alert('존재하지 않는 설문지입니다.')
        return ''
      } else if (!this.survey.isUse && !this.isAdmin) {
        this.isFetchedData = true
        alert('비공개 설문입니다.')
        return ''
      } else if (startDate > currentDate && !this.isAdmin) {
        this.isFetchedData = true
        alert('시작되지 않은 설문이므로 답변이 불가합니다.')
        return ''
      } else if (endDate < currentDate && !this.isAdmin) {
        this.isFetchedData = true
        alert('끝난 설문이므로 답변이 불가합니다.')
        return ''
      }
      if (this.res === '' || !this.res || this.isAdmin) {
        this.setSurvey(linkQuestion)
      } else {
        this.fetchAnswerListByRespondent({
          respondent: this.res
        }).then((response) => {
          if (response.data.length > 0) {
            resCnt = response.data.filter(el => { return el.surveyId === parseInt(this.surveyId) })
          }
          if (this.survey.maxResponseCnt !== 0 && resCnt.length >= this.survey.maxResponseCnt) {
            this.isFetchedData = true
            alert('최대 답변횟수를 초과하여 답변이 불가합니다.')
            return ''
          } else {
            this.setSurvey(linkQuestion)
          }
        }).catch((err) => { this.isFetchedData = true; throw Error(err); })
      }
    }).catch((err) => { throw Error(err) })
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/style/variable.scss';
.layout-container{
  position: relative;
  max-width: 100%;
  padding: 0;
  margin: 0;
    /* &:before{
      display: block; content: ''; clear: both;
      position: absolute; width: 100%; height: 100%;
      background: url('../../static/images/high-angle-multiple-election-questionnaires.jpg') center no-repeat;
      background-size: cover;
      opacity: 0.5;
    } */
}
.flex-container{
  max-width: 1040px;
  margin: 5em auto;
  padding: 0 20px;
}
@media screen and (max-width: $mobile) {
  .flex-container {
    margin: 20px auto;
  }
}
</style>
