
<template>
  <v-container class="main-wrapper" v-if="section !== undefined && section.isVisible">
    <v-layout class="flex-flow-wrap p-t-16" text-xs-left>
      <div :class="(question.questionType !== 5 && question.questionType !== 9) ? 'title-wrapper' : 'info-wrapper'"
        v-if="question.questionType !== 8"
      >
        <span v-if="question.isRequired" class="display-no notNull"></span>
        <span
          class="title title-layout display-no font-weight-bold p-y-16"
          v-if="(question.displayNo !== '' || survey.isAutoSeq) && question.questionType !== 5 && question.questionType !== 8 && question.questionType !== 9"
        >
          {{survey.isAutoSeq ? (idx + 1) : question.displayNo}}
        </span>
        <div class="ql-editor" :class="{'info-editor': (question.questionType === 5 || question.questionType === 9), 'display-title' : (question.questionType !== 5 && question.questionType !== 9)}" v-html="question.questionTitle"></div>
      </div>
      <answer-drop-down class="d-block" v-if="question.choiceItems !== undefined && question.questionType === 6"
        :question="question"
        :change-value="changeValue"
      ></answer-drop-down>
      <!-- <answer-radio
        v-if="question.choiceItems !== undefined && question.questionType === 1"
        :question="question"
        :change-value="changeValue"
      ></answer-radio> -->
      <answer-line
        v-if="question.questionType === 8"
        :question="question"
      >
      </answer-line>
      <answer-thumb
        v-else-if="question.choiceItems !== undefined && question.questionType === 4"
        :question="question"
        :change-value="changeValue"
      ></answer-thumb>
      <answer-grade
        v-else-if="question.choiceItems !== undefined && question.questionType === 41"
        :question="question"
        :change-value="changeValue"
      ></answer-grade>
      <answer-star
        v-else-if="question.choiceItems !== undefined && question.questionType === 42"
        :question="question"
        :change-value="changeValue"
      ></answer-star>
      <answer-heart
        v-else-if="question.choiceItems !== undefined && question.questionType === 43"
        :question="question"
        :change-value="changeValue"
      ></answer-heart>
      <v-flex class="input-flex" v-else-if="question.choiceItems !== undefined && question.questionType === 1">
        <answer-radio
        :question="question"
        :change-value="changeValue"
      ></answer-radio>
        <answer-text
          :question="question"
          :insert-txt="insertTxt"
          :sub-answer="subAnswer"
        ></answer-text>
      </v-flex>
      <v-flex class="input-flex" v-else-if="question.choiceItems !== undefined && question.questionType === 2">
        <answer-checkbox
          :question="question"
          :change-value="changeValue"
        ></answer-checkbox>
        <answer-text
          :question="question"
          :insert-txt="insertTxt"
          :sub-answer="subAnswer"
        ></answer-text>
      </v-flex>
      <v-flex class="input-flex" v-else-if="question.questionType === 7 || question.questionType === 3">
        <answer-text
          v-if="question.questionType === 3"
          :question="question"
          :insert-txt="insertTxt"
          :sub-answer="subAnswer"
        ></answer-text>
        <answer-textarea
          v-if="question.questionType === 7"
          :question="question"
          :insert-txt="insertTxt"
          :sub-answer="subAnswer"
        ></answer-textarea>
      </v-flex>
      <v-flex class="input-flex" v-else-if="question.questionType === 31">
        <AnswerPhoneText
          :question="question"
          :insert-txt="insertTxt"
          :sub-answer="subAnswer"
        ></AnswerPhoneText>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import AnswerRadio from '@/components/common/AnswerRadio.vue'
import AnswerThumb from '@/components/common/AnswerThumb.vue'
import AnswerGrade from '@/components/common/AnswerGrade.vue'
import AnswerStar from '@/components/common/AnswerStar.vue'
import AnswerHeart from '@/components/common/AnswerHeart.vue'
import AnswerCheckbox from '@/components/common/AnswerCheckbox.vue'
import AnswerText from '@/components/common/AnswerText.vue'
import AnswerPhoneText from '@/components/common/AnswerPhoneText.vue'
import AnswerTextarea from '@/components/common/AnswerTextarea.vue'
import AnswerDropDown from '@/components/common/AnswerDropDown.vue'
import AnswerLine from '@/components/common/AnswerLine.vue'
import _ from 'lodash'

export default {
  name: 'QuestionCmp',
  props: {
    question: Object,
    updateQuestion: Function,
    resultView: Function,
    idx: Number,
    section: Object,
    resetNullValue: Function,
    questions: Array,
    survey: Object
  },
  data () {
    return {
      subAnswer: {}
    }
  },
  methods: {
    changeValue (n, chkAnswers) {
      this.resetNullValue()
      if (this.question.questionType === 1 || this.question.questionType === 4 || this.question.questionType > 40) {
        this.question.answers = [n]
      } else if (this.question.questionType === 2) {
        this.question.answers = chkAnswers
      } else if (this.question.questionType === 7) {
        this.question.answers = [this.question.subAnswer[0]]
      }
      this.$emit('updateQuestion', _.cloneDeep(this.question))
      this.resultView(this.question.questionId, this.question.sectionId)
    },
    insertTxt (question, isShortAnswer) {
      if (question.questionType === 7) {
        if (this.subAnswer[0] !== undefined) {
          this.question.answers = _.cloneDeep(this.subAnswer[0]).trim()
          this.$emit('updateQuestion', _.cloneDeep(this.question))
        }
      } else {
        if (isShortAnswer) this.question.answers = question.answers
        this.question.subAnswer = _.cloneDeep(this.subAnswer)
        this.$emit('updateQuestion', _.cloneDeep(this.question))
        if (question.answers.length > 0 && (!isShortAnswer || (isShortAnswer && Object.keys(this.question.subAnswer).length > 0))) {
          for (const answerNum of question.answers) {
            if (this.question.subAnswer[answerNum] === undefined) continue
            if (this.question.subAnswer[answerNum].trim().length === 0) {
              this.question.subAnswer[answerNum] = this.question.subAnswer[answerNum].trim()
            } else {
              this.question.subAnswer[answerNum] = this.question.subAnswer[answerNum]
            }
          }
        }
        this.$emit('updateQuestion', _.cloneDeep(this.question))
      }
    },
    initComp () {
      if (this.question.questionType === 7) {
        this.subAnswer[0] = this.question.answers
      }
      if (this.question.choiceItems !== undefined) {
        this.question.choiceItems.filter(choice => {
          if (choice.hasSubQuestion && this.question.subAnswer === undefined) {
            this.question.subAnswer = {}
          } else if (choice.hasSubQuestion && this.question.subAnswer !== undefined) {
            this.subAnswer = this.question.subAnswer
          }
        })
        // this.questionCopy.choiceItems = _.cloneDeep(this.question.choiceItems)
      }
    }
  },
  created () {
    this.initComp()
  },
  components: {
    AnswerPhoneText,
    'answer-radio': AnswerRadio,
    'answer-thumb': AnswerThumb,
    'answer-grade': AnswerGrade,
    'answer-star': AnswerStar,
    'answer-heart': AnswerHeart,
    'answer-checkbox': AnswerCheckbox,
    'answer-text': AnswerText,
    'answer-textarea': AnswerTextarea,
    'answer-drop-down': AnswerDropDown,
    'answer-line': AnswerLine
  }
}
</script>

<style>
.main-wrapper .title-wrapper {
  display: table;
  width: 100%;;
  margin-bottom: 21px;
}
.main-wrapper .flex-flow-wrap .display-no {
  display: table-cell !important;
  width: auto !important;
  min-width: 0 !important;
  padding-top: 0 !important;
  vertical-align: top;
  padding-bottom: 0;
  word-break: normal;
}
.main-wrapper .flex-flow-wrap .display-title {
  display: table-cell !important;
  width: auto !important;
  margin-top: 5px;
  margin-bottom: 0;
  vertical-align: middle;
}
.notNull:before {
  display: block;
  content: '*';
  clear: both;
  color: red;
  position: absolute;
  font-size: 18px;
  left: -10px; top: 5px;
}
.notNull {
  position: relative;
  padding-left: 5px;
}
</style>
