import Vue from 'vue'
import Router from 'vue-router'
import SurveyUser from '@/views/SurveyUser'
import SurveyUserFinish from '@/components/users/SurveyUserFinish'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: '/u2s-web/',
  routes: [
    {
      path: '/param/:param/res/:res?',
      name: 'SurveyUser',
      component: SurveyUser
    },
    {
      path: '/param/:param/res/:res?/rdurl/:rdurl?',
      name: 'SurveyUser',
      component: SurveyUser
    },
    {
      path: '/SurveyUserFinish',
      name: 'SurveyUserFinish',
      component: SurveyUserFinish
    }
  ]
})
