import Vue from 'vue'
import Vuex from 'vuex'
import survey from './modules/survey/survey'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    survey
  }
})
