<template>
  <v-layout class="flex-flow-wrap">
    <v-flex class="p-y-0" v-if="isEditable">
      <div v-for="n in question.choiceItems.length" :key="n"
          style="display: block; width: 100%; margin-bottom: 0px;"
        >
        <v-checkbox
          :disabled="true"
          @change="changeValue(question.choiceItems[n-1].choiceValue, answer)"
          :label="question.choiceItems[n-1].content"
          hide-details
        ></v-checkbox>
      </div>
    </v-flex>
    <v-flex class="p-y-0" v-else>
      <div v-for="n in question.choiceItems.length" :key="n"
          style="display: block; width: 100%; margin-bottom: 0px;"
        >
        <v-checkbox
          @change="changeValue(question.choiceItems[n-1].choiceValue, answer)"
          v-model="answer"
          :label="question.choiceItems[n-1].content"
          :value="question.choiceItems[n-1].choiceValue"
          hide-details
        ></v-checkbox>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'AnswerCheckbox',
  props: {
    changeValue: Function,
    question: Object,
    isEditable: Boolean
  },
  data () {
    return {
      answer: this.question.answers
    }
  }
}
</script>
