import { createNamespacedHelpers } from 'vuex'
import mutations from './mutations'
import actions from './actions'

const { mapGetters: surveyMapGetters, mapActions: surveyMapActions } = createNamespacedHelpers('survey')

export { surveyMapGetters, surveyMapActions }

export default {
  namespaced: true,

  state: {
    sections: [],
    questions: [],
    answerList: [],
    answer: {}
  },

  getters: {
    getSectionsList: state => {
      return state.sections
    },
    getQuestionsList: state => {
      return state.questions
    },
    getAnswerList: state => {
      return state.answerList
    },
    getAnswerByAnswerId: state => {
      return state.answer
    }
  },

  mutations,

  actions

}
