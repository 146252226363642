<template>
  <v-layout v-if="question.questionType === 7" class="input-flex">
    <textarea
      v-if="isIe"
      class="ie-textarea width-p-100"
      v-model="inputTxt"
      :rules="textRules"
      @input="subAnswer[0] = inputTxt; insertTxt(question)"
    ></textarea>
    <v-textarea
      v-model="subAnswer[0]"
      :rules="textRules"
      outline
      :class="{'none': isIe }"
      @input="insertTxt(question)"
    ></v-textarea>
  </v-layout>
</template>

<script>
export default {
  name: 'AnswerTextarea',
  props: {
    insertTxt: Function,
    question: Object,
    subAnswer: Object
  },
  computed: {
    isIe () {
      let agent = navigator.userAgent.toLowerCase()
      if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (agent.indexOf("msie") != -1) ) { // IE 일 경우
        return true
      } else {
        return false
      }
    }
  },
  data () {
    return {
      textRules: [
        v => !(/[¸|ː|§]/.test(v)) || '해당 특수문자는 사용할 수 없습니다.',
        v => typeof v === 'string' && v.trim().length > 0 || '공백만 입력하는 것은 불가합니다.'
      ],
      inputTxt: ''
    }
  }
}
</script>

<style lang="scss" scope>
.ie-textarea{
  border: 2px solid #444;
  border-radius: 3px;
  padding: 8px;
}
.none {
  visibility: hidden;
  width: 0;
  height: 0;
  overflow: hidden;
}
.v-textarea.v-text-field--enclosed .v-text-field__slot textarea{
    margin-top: 5px;
  }
</style>
