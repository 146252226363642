<template>
  <v-layout class="flex-flow-wrap btn-wrapper" row>
    <v-flex v-if="isEditable">
      <v-btn
        class="thumb-btn"
        v-for="n in question.choiceItems.length"
        :key="n"
        icon large flat
        hide-details
      >
        <v-icon>thumb_up</v-icon>
      </v-btn>
    </v-flex>
    <v-flex v-if="isEditable === undefined || !isEditable">
      <v-btn
        class="thumb-btn"
        :class="{'active': question.answers[0] !== undefined && question.answers[0] >= n - 1}"
        v-for="n in question.choiceItems.length"
        :key="n"
        @click="changeValue(question.choiceItems[n - 1].choiceValue)"
        icon large flat
        hide-details
      >
        <v-icon>thumb_up</v-icon>
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'AnswerThumb',
  props: {
    question: Object,
    changeValue: Function,
    isEditable: Boolean
  },
  data () {
    return {
      answer: this.changeValue
    }
  },
  mounted () {
    if (this.question.answers !== undefined && this.question.answers[0] !== undefined) {
      this.answer = this.question.answers[0]
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scope>
@import '../../assets/style/variable.scss';

.thumb-btn{
  margin-left: 0;
  margin-right: 20px;
}

@media screen and (max-width: $mobile) {
  .thumb-btn{
    margin-right: 10px;
    &.v-btn--large{
      width: 30px;
      height: 30px;
    }
    .v-icon{
      font-size: 18px;
    }
  }
}
</style>
