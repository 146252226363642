
export default {
  setSurveysList (state, payload) {
    state.surveys = payload
  },

  setSectionsList (state, payload) {
    state.sections = payload
  },

  setQuestionsList (state, payload) {
    state.questions = payload
  },

  setAnswerList (state, payload) {
    state.answerList = payload
  },

  setAnswer (state, payload) {
    state.answer = payload
  }

}
