// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import '@babel/polyfill'
import Vue from 'vue'
import App from './App'
import router from './router'
import Vuetify from 'vuetify'
import store from './store'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@/assets/style/common.scss'
import VueMask from 'v-mask'
import { VueMaskDirective } from 'v-mask'

Vue.use(Vuetify, { iconfont: 'fa' })
Vue.use(VueMask)
Vue.config.productionTip = false
Vue.directive('mask', VueMaskDirective)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>',
  store
})

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
