import api from './index'

export default {
  getSurveyInfo: (surveyId) => api.get(`/library/survey/${surveyId}`),
  getSectionsList: (surveyId) => api.get(`/library/section/survey/${surveyId}`),
  getQuestionsList: (surveyId) => api.get(`/library/question/survey/${surveyId}`),
  getAnswerListByRespondent: (respondent) => api.get(`/answer/respondent/${respondent}`),
  getAnswerByAnswerId: (answerId) => api.get(`/answer/id/${answerId}`),
  createAnswerInfo: (params) => api.post('/answer', params),
  submitResult: (params) => api.post('/answer/result', params),
  submitResultForDropouts: (params) => api.post('/answer/dropouts', params)
}
