<template>
  <v-app id="app">
    <div class="survey-card-bg"></div>
    <router-view/>
  </v-app>
</template>

<script>
import 'vuetify/dist/vuetify.min.css'

export default {
  name: 'App'
}
</script>

<style lang="scss">
#app {
  font-family: 'Noto Sans KR', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.survey-card-bg{
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0; top: 0;
  z-index: 0;
  &:before{
    display: block; content: ''; clear: both;
    position: absolute; width: 100%; height: 100%;
    background: url('../static/images/high-angle-multiple-election-questionnaires.jpg') center no-repeat;
    background-size: cover;
    opacity: 0.5;
  }
}
</style>
