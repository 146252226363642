import SurveyAPI from '../../../api/api-survey'
export default {
  async fetchSurveyInfo ({ commit }, { surveyId }) {
    try {
      return SurveyAPI.getSurveyInfo(surveyId)
    } catch (error) {
      throw Error(error)
    }
  },

  async fetchSurveyData ({ commit }, { surveyId }) {
    try {
      const sectionsResponse = await SurveyAPI.getSectionsList(surveyId)
      const questionsResponse = await SurveyAPI.getQuestionsList(surveyId)
      commit('setSectionsList', sectionsResponse.data)
      commit('setQuestionsList', questionsResponse.data)
    } catch (error) {
      throw Error(error)
    }
  },

  async fetchSectionList ({ commit }, { surveyId }) {
    try {
      const sectionsResponse = await SurveyAPI.getSectionsList(surveyId)
      commit('setSectionsList', sectionsResponse.data)
    } catch (error) {
      throw Error(error)
    }
  },

  async fetchQuestionList ({ commit }, { surveyId }) {
    try {
      const questionsResponse = await SurveyAPI.getQuestionsList(surveyId)
      commit('setQuestionsList', questionsResponse.data)
    } catch (error) {
      throw Error(error)
    }
  },

  async fetchAnswerListByRespondent ({ commit }, { respondent }) {
    try {
      const response = await SurveyAPI.getAnswerListByRespondent(respondent)
      commit('setAnswerList', response.data)
      return response
    } catch (error) {
      throw Error(error)
    }
  },

  async fetchAnswerByAnswerId ({ commit }, { answerId }) {
    try {
      const response = await SurveyAPI.getAnswerByAnswerId(answerId)
      commit('setAnswer', response.data)
      return response
    } catch (error) {
      throw Error(error)
    }
  },

  async createAnswerInfo ({ commit }, { params }) {
    try {
      return await SurveyAPI.createAnswerInfo(params)
    } catch (error) {
      throw Error(error)
    }
  },

  async submitResult ({ commit }, { params }) {
    try {
      return await SurveyAPI.submitResult(params)
    } catch (error) {
      throw Error(error)
    }
  },

  async submitResultForDropouts ({ commit }, { params }) {
    try {
      SurveyAPI.submitResultForDropouts(params)
    } catch (error) {
      throw Error(error)
    }
  }

}
