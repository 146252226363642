<template>
  <v-layout class="input-flex">
    <v-flex class="input-flex" v-if="question.questionType === 31" wrap>
      <input
        v-if="isIe"
        class="width-p-100"
        v-model="inputTxt"
        return-masked-value
        v-mask="`###-####-####`"
        style="border-bottom: 2px solid #444;"
        @input="question.answers = inputTxt; insertTxt(question, true);"
      />
      <v-text-field
        outline
        class="text-field"
        :class="{'none': isIe}"
        return-masked-value
        v-mask="`###-####-####`"
        @input="insertTxt(question, true)"
        :rules="textRules"
        v-model="question.answers"
      ></v-text-field>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'AnswerText',
  props: {
    question: Object,
    subAnswer: Object,
    insertTxt: Function,
  },
  computed: {
    isIe () {
      let agent = navigator.userAgent.toLowerCase()
      if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (agent.indexOf("msie") != -1) ) { // IE 일 경우
        return true
      } else {
        return false
      }
    }
  },
  data () {
    return {
      textRules: [
        v => (/^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/.test(v)) || '핸드폰 번호 양식에 맞게 입력해주세요.',
        v => typeof v === 'string' && v.trim().length > 0 || '공백만 입력하는 것은 불가합니다.'
      ],
      inputTxt: []
    }
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scope>
.v-text-field>.v-input__control>.v-input__slot>.v-text-field__slot input{
  margin-top: 10px;
}
.none {
  visibility: hidden;
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>
