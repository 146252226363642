<template>
  <v-layout class="m-t-16" style="display: inline-block !important;">
    <v-select
      v-if="question.answers === undefined"
      style="width: auto !important;"
      :items="question.choiceItems"
      item-value="choiceValue"
      item-text="content"
      label="선택하세요"
      hide-details
    ></v-select>
    <v-select
      v-else
      v-model="question.answers[0]"
      style="width: auto !important;"
      :items="question.choiceItems"
      item-value="choiceValue"
      @change="changeValue()"
      item-text="content"
      label="선택하세요"
      hide-details
    ></v-select>
  </v-layout>
</template>

<script>
export default {
  name: 'AnswerDropDown',
  props: {
    question: Object,
    changeValue: Function
  }
}
</script>
