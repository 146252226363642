import axios from 'axios'
const api = axios.create({
  auth: {
    username: 'svUser',
    password: 'svUser~!@#'
  },
  // baseURL: 'http://27.96.135.34:18081/u2s-api'
  baseURL: 'https://u2s-api.u2cloud.co.kr/u2s-api'
})

export default api
