
<template>
  <v-stepper v-model="el">
    <v-progress-linear v-model="visibleQuestionPercentage"></v-progress-linear>
    <v-layout v-if="visibleSection[el-1].sectionTitle !== ''" class="">
      <!-- <v-flex xs12 class="text-xs-left"><img src="@/assets/images/logo.png" alt="u2survey logo"></v-flex> -->
      <v-flex xs10 class="text-xs-left m-l-30 font-weight-bold headaer-title-text">{{visibleSection[el-1].sectionTitle}}</v-flex>
      <v-flex xs2 class="text-xs-left m-r-30 font-weight-bold text-xs-right headaer-title-progress">{{ visibleQuestionPercentage }}%</v-flex>
    </v-layout>
    <v-stepper-items id="scroll-wrap">
      <v-stepper-content
        v-for="(section, index) in visibleSection"
        :key="index"
        :step="index + 1">
          <div row v-for="question in questionInSection(section.sectionId, visibleSection[el-1].sectionId)" :key="question.questionId">
            <div class="text-xs-left error-box" v-if="(answerNullVal.includes(question.questionId) || answerChecker.includes(question.questionId)) && question.isVisible && question.isUse">
              <v-btn dark v-if="answerNullVal.includes(question.questionId) && section.sectionId === question.sectionId && question.isUse">
                <v-icon dark>info</v-icon>&nbsp;&nbsp;&nbsp;필수항목입니다.
              </v-btn>
                <v-btn dark v-if="answerChecker.includes(question.questionId) && section.sectionId === question.sectionId && question.isUse">
                  <v-icon dark>info</v-icon>&nbsp;&nbsp;&nbsp;답안에 입력 불가능한 특수문자가 포함되어 있습니다.
                </v-btn>
            </div>
            <question-cmp
              v-show="question.isVisible && question.isUse"
              :question="question"
              @updateQuestion="updateQuestion"
              :section="section"
              :result-view="resultView"
              :idx="idxInQuestion(question.questionId, visibleSection[el-1].sectionId)"
              :reset-null-value="resetNullValue"
              :questions="questions"
              :survey="survey"
            ></question-cmp>
          </div>
          <div class="submit-btn-wrapper">
            <v-btn
              depressed
              @click="prev(section.sectionId, index)"
              v-if="el !== 1"
            >
                이전 페이지
            </v-btn>
            <v-btn
              depressed
              color="blue lighten-2"
              @click="next(section.sectionId, index)"
              :disabled="!isSubmitBtn"
              :class="{'none': el === visibleSection.length && !isSubmitBtn || el === visibleSection.length && isSubmitBtn}"
            >
              다음 페이지
            </v-btn>
            <v-btn
              depressed
              color="blue lighten-2"
              @click="next(section.sectionId, index)"
              :disabled="!isSubmitBtn || !send"
              v-if="el === visibleSection.length"
            >
              {{submitMsg }}
            </v-btn>
          </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>

import Question from '@/components/users/Question.vue'

export default {
  name: 'Steppers',
  props: {
    questions: Array,
    resultView: Function,
    visibleQuestionPercentage: Number,
    visibleQuestion: Array,
    sectionArr: Array,
    visibleSection: Array,
    submitDropoutsAnswer: Function,
    getAnswerStr: Function,
    survey: Object,
    send: Boolean,
    isSubmitResult: Boolean,
    isAdmin: Boolean
  },
  data () {
    return {
      el: 1,
      answerNullVal: [],
      answerChecker: [],
      nextText: 'Next'
    }
  },
  methods: {
    closeBrowser () {
      if (!this.isSubmitResult) {
        this.submitDropoutsAnswer()
      }
    },
    next (section, index) {
      if (this.checkValue(this.visibleSection[index], index)) {
        if (index < this.visibleSection.length - 1) {
          this.el += 1
          this.scrollTop()
        }
      }
    },
    scrollTop () {
      let scrollWrapper = document.getElementById('scroll-wrap')
      scrollWrapper.scrollIntoView()
      scrollWrapper.scrollTo({ top: 0 })
    },
    prev (section, index) {
      if (index >= 1) {
        this.answerChecker = []
        this.el -= 1
        this.scrollTop()
      }
    },
    resetNullValue () {
      this.answerNullVal = []
    },
    updateQuestion (updateQuestion) {
      let idx = _.findIndex(this.questions, { questionId: updateQuestion.questionId })
      if (idx > -1) {
        this.questions.splice(idx, 1, updateQuestion)
      }
    },
    checkValue (section, sectionIdx) {
      this.answerNullVal = []
      this.answerChecker = []
      if (this.survey.isEditable || this.isAdmin) {
        return true
      } else {
        let chkSectionArr = this.sectionArr.map((arr, idx) => {
          if (idx <= sectionIdx) {
            return arr.sectionId
          }
        })
        this.questions.forEach(question => {
          if (chkSectionArr.includes(question.sectionId)) {
            if (question.answers.includes('¸') || question.answers.includes('ː') || question.answers.includes('§')) {
              this.answerChecker.push(question.questionId)
            } else if (question.sectionId === section.sectionId && question.isRequired && question.isVisible && question.isUse) {
              if (question.answers.length === 0) {
                this.setAnswerNn(question.questionId)
              } else {
                if (question.choiceItems === undefined) {
                  if (question.subAnswer === '') {
                    this.setAnswerNn(question.questionId)
                  }
                } else {
                  question.choiceItems.forEach(choice => {
                    if (question.answers.includes(choice.choiceValue)) {
                      question.answers.filter(answer => {
                        if (answer === choice.choiceValue && choice.hasSubQuestion) {
                          if (!question.subAnswer.hasOwnProperty(answer) || question.subAnswer[answer] === '') {
                            this.setAnswerNn(question.questionId)
                          } else if (question.subAnswer[answer].includes('¸') || question.subAnswer[answer].includes('ː') || question.subAnswer[answer].includes('§')) {
                            this.answerChecker.push(question.questionId)
                          }
                        }
                      })
                    }
                  })
                }
              }
            }
          }
        })
        if (this.answerNullVal.length === 0 && this.answerChecker.length === 0) {
          if (section === this.visibleSection[this.visibleSection.length - 1]) {
            this.getAnswerStr(section)
          }
          return true
        } else {
          return false
        }
      }
    },
    setAnswerNn (question) {
      this.answerNullVal.push(question)
    },
    sendBtnSet () {
      if (this.el === this.visibleSection.length) {
        this.nextText = '제출'
      } else {
        this.nextText = '다음 페이지'
      }
    },
    questionInSection (currentSectionId, sectionId) {
      if (currentSectionId === sectionId) {
        return this.questions.filter(el => el.isUse && el.sectionId === sectionId)
      } else {
        return []
      }
    },
    idxInQuestion (questionId, sectionId) {
      return _.findIndex(this.questions.filter(el => el.isVisible && el.isUse && el.sectionId === sectionId && el.questionType !== 5 && el.questionType !== 8 && el.questionType !== 9), {questionId: questionId})
    },
    setBtnValue () {
      let returnValue = true
      const currentSectionId = this.visibleSection[this.el - 1].sectionId
      if (this.survey.isEditable) return true
      const currentPageQuestions = this.questions.filter(question => {
        return question.sectionId === currentSectionId
        && question.isVisible
        && question.isRequired
        && question.isUse
        && question.questionType !== 5
        && question.questionType !== 8
        && question.questionType !== 9
      })
      currentPageQuestions.forEach(currentPageQuestion => {
        if (currentPageQuestion.answers !== undefined) {
          if ((currentPageQuestion.questionType === 3 || currentPageQuestion.questionType === 31 || currentPageQuestion.questionType === 7) && currentPageQuestion.subAnswer === '') {
            returnValue = false
          } else if (currentPageQuestion.answers.length === 0) {
            returnValue = false
          }
        }
      })
      return returnValue
    }
  },
  computed: {
    isSubmitBtn () {
      if (this.isAdmin) {
        return true
      } else {
        return this.setBtnValue()
      }
    },
    
    valueDeterminate () {
      return (this.el / this.visibleSection.length) * 100
    },
    submitMsg () {
      return this.survey.submitText === '' ? '제출' : this.survey.submitText
    }
  },
  components: {
    'question-cmp': Question
  },
  watch: {
    el () {
      this.sendBtnSet()
    }
  },
  created () {
    window.addEventListener("beforeunload", this.closeBrowser)
  },
  mounted () {
    this.sendBtnSet()
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/style/variable.scss';
  .none {display: none !important;}
  /* alert */
  .error-box{
    margin-top: 16px;
    .v-btn{
      border: 1px solid $error-color;
      color: $error-color;
      background-color: white !important;
      &::before{display: none;}
    }
    &+.main-wrapper{
      margin-top: 0;
      padding-top: 0;
    }
  }
  .v-stepper__header{height: auto}
  /deep/ .v-input--selection-controls__ripple {
    z-index : 1;
  }
  h1 {
    margin: 2.5em 0 1em;
    text-align: left;
    color: #fff;
    height: 2.8em;
    font-weight: normal;
    max-width: calc(100% - 4em);
    span {
      padding: 0.4em 2em 0.65em 1em;
      background-color: #5AA3DD;
      position: relative;
      word-wrap: break-word;
      text-overflow: ellipsis;
      height: 2.8em;
      display: inline-block;
      line-height: 2em;
      &:before,
      &:after  {
        display: block;
        content: '';
        clear: both;
        position: absolute;
        right: -1.5em;
      }
      &:before {
        bottom: 0;
        border-bottom:1.56em solid #5AA3DD;
        border-right: 1.56em solid transparent;
        border-left: 1.56em solid  transparent;
      }
      &:after {
        bottom: 1.24em;
        border-top: 1.56em solid #5AA3DD;
        border-right: 1.56em solid transparent;
        border-left: 1.56em solid transparent;
      }
    }
  }
  @media screen and (max-width: $mobile) {
    h1 {
      margin: 1em 0;
    }
    .container {
      padding: 0 10px;
    }
    .submit-btn-wrapper {
      margin: 10px;
    }
  }
</style>
